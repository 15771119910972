<template>
  <b-container class="p-0">
    <!-- Data Deletion Request -->
    <b-row class="my-2">
      <b-col cols="12">
        <h3>Data Deletion Request</h3>
        <b-card class="mt-1 mb-1">
          <b-row>
            <b-col>
              To have your user data deleted, write to <a href="mailto:admin@dailysurvivorpool.com">admin@dailysurvivorpool.com</a>. Please include your full name and email address.
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BContainer, BRow, BCol,
  BCard,
} from 'bootstrap-vue'

import HomeBackground from '@/assets/images/backgrounds/home.png'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BCard,
  },
  data() {
    return {}
  },
  created() {
    document.body.style.backgroundImage = `url(${HomeBackground})`
  },
}
</script>

<style scoped>
.transparent {
  opacity: 0.2;
}
</style>
